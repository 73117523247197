import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const creditRatesEvent = (eventLabel = 'Ставки по кредитам|Узнать свою ставку') => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Кредиты',
      eventAction: 'Переход из плиток с деталями',
      eventLabel,
    }),
  );
};
