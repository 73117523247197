import React from 'react';

import { Avatar, Skeleton, Space, Typography } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';

import styles from '../styles/BarChart.module.scss';
import { BarChartProps } from '../types/BarChart.types';
import { normalizeData } from '../utils/normalizeData';
import { toFixedRate } from '../utils/toFixedRate';

export const BarChart = ({
  data,
  barDirection = 'horizontal',
  reverse = false,
  legend = false,
  labelColor = 'blue',
  className,
  onBarClick,
}: BarChartProps) => {
  const cx = useStyles(styles);
  const isVerticalBar = barDirection === 'vertical';
  const isHorizontalBar = barDirection === 'horizontal';
  const direction = isHorizontalBar ? 'column' : 'row';
  const reductionFactor = legend && isHorizontalBar ? 0.5 : 1;

  const normalizedData = normalizeData(data);

  const getLabelStyles = (value: number) => {
    const yPos = `calc(100% - ${value * reductionFactor}% - 32px)`;

    if (isVerticalBar) {
      return reverse ? { bottom: yPos } : { top: yPos };
    }

    return {};
  };

  return (
    <Space
      size={isHorizontalBar ? 16 : 8}
      direction={direction}
      justify={data.length > 3 ? 'space-between' : 'space-around'}
      className={cx(className, 'wrapper', { verticalBarWrapper: isVerticalBar, reverse })}
    >
      {normalizedData.map(({ value, label, href, logo, normalizedValue }, index) =>
        // eslint-disable-next-line no-nested-ternary
        value ? (
          href ? (
            <Typography.Link href={href} key={index} onClick={() => onBarClick?.(label, value)}>
              <div
                className={cx('barContainer', {
                  horizontalBarContainer: isHorizontalBar,
                  verticalBarContainer: isVerticalBar,
                  reverse,
                })}
              >
                <div
                  className={cx('bar', { horizontalBar: isHorizontalBar, verticalBar: isVerticalBar })}
                  style={{ [isHorizontalBar ? 'width' : 'height']: `${normalizedValue * reductionFactor}%` }}
                >
                  <div
                    className={cx('label', {
                      horizontalLabel: isHorizontalBar,
                      verticalLabel: isVerticalBar,
                      blueLabelColor: labelColor === 'blue',
                      darkLabelColor: labelColor === 'dark',
                    })}
                    style={getLabelStyles(normalizedValue)}
                  >{`${toFixedRate(value)}%`}</div>
                </div>
                {legend && isHorizontalBar && (
                  <Typography.Text size={14} strong className={styles.value}>
                    {label}
                  </Typography.Text>
                )}
                {logo && <Avatar src={logo} size={36} className={styles.logo} />}
              </div>
            </Typography.Link>
          ) : (
            <div
              className={cx('barContainer', {
                horizontalBarContainer: isHorizontalBar,
                verticalBarContainer: isVerticalBar,
                reverse,
              })}
            >
              <div
                className={cx('bar', { horizontalBar: isHorizontalBar, verticalBar: isVerticalBar })}
                style={{ [isHorizontalBar ? 'width' : 'height']: `${normalizedValue * reductionFactor}%` }}
              >
                <div
                  className={cx('label', {
                    horizontalLabel: isHorizontalBar,
                    verticalLabel: isVerticalBar,
                    blueLabelColor: labelColor === 'blue',
                    darkLabelColor: labelColor === 'dark',
                  })}
                  style={getLabelStyles(normalizedValue)}
                >{`${toFixedRate(value)}%`}</div>
              </div>
              {legend && isHorizontalBar && (
                <Typography.Text size={14} strong className={styles.value}>
                  {label}
                </Typography.Text>
              )}
              {logo && <Avatar src={logo} size={36} className={styles.logo} />}
            </div>
          )
        ) : (
          <Skeleton.Button
            style={{ [isHorizontalBar ? 'height' : 'width']: '36px', [isHorizontalBar ? 'width' : 'height']: '100%' }}
          ></Skeleton.Button>
        ),
      )}
    </Space>
  );
};
