import React from 'react';

import { Card, Icon, Space, Typography } from '@sravni/react-design-system';
import { useIsMobile, useStyles } from '@sravni/react-utils';

import styles from '../styles/PropositionCard.module.scss';
import { PropositionCardProps } from '../types/PropositionCard.types';

export const PropositionCard: React.FC<PropositionCardProps> = (props) => {
  const { title, subTitle, icon, children } = props;
  const cx = useStyles(styles);
  const isMobile = useIsMobile();

  return (
    <Card className={cx('propositionCard')} size={isMobile ? 16 : 24}>
      <Space direction="vertical">
        <Space justify="space-between" className="h-mb-12">
          <Space direction="vertical" size={2}>
            <Typography.Heading level={4}>{title}</Typography.Heading>
            <Typography.Text size={12} className="h-color-D60">
              {subTitle}
            </Typography.Text>
          </Space>
          <Icon size={20} icon={icon} shape="round" background="light" />
        </Space>
        {children}
      </Space>
    </Card>
  );
};
