import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeName } from '@sravni/design-system-theme';
import { Layout } from '@sravni/react-design-system';
import { PreFooter } from '@sravni/react-pre-footer';

import { LayoutWrapper } from '../../components/LayoutWrapper';
import Materials from '../../components/Materials';
import { MonitoringBlock } from '../../components/MonitoringBlock';
import { ProductBlock } from '../../components/ProductBlock';
import { SecurityBlock } from '../../components/SecurityBlock';
import { SpecialProjects } from '../../components/SpecialProjects';
import { TopServicesBlock } from '../../components/TopServicesBlock';
import { useMetadata } from '../../hooks/metadata';
import { fetchBanners } from '../../store/reducers/advertising';

interface IIndexPageProps {
  theme: ThemeName;
}

const IndexPage: NextPage<IIndexPageProps> = () => {
  const { prefooter } = useMetadata();
  const dispatch = useDispatch();

  // TODO нужно для а/б теста
  // const abTestingSdk = useAbTestingSdk();
  // const isBtest = abTestingSdk.checkExperimentVariant('45b0dac3-ad00-4e0a', '1');

  useEffect(() => {
    dispatch(
      // @ts-ignore
      fetchBanners({
        params: {
          BannerTypes: ['top', 'teaserInPropositions'],
          Page: 'home',
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      <ProductBlock />
      <TopServicesBlock />
      <MonitoringBlock />
      <Materials />
      <SpecialProjects />
      <LayoutWrapper topBorders={false} bottomBorders={false} whiteBackground>
        <Layout className="h-pt-40">
          <SecurityBlock />
        </Layout>
      </LayoutWrapper>
      <PreFooter columns={prefooter} />
    </>
  );
};

export default IndexPage;
