import React from 'react';

import { useIsMobile, useStyles } from '@sravni/react-utils';

import { RecentActionsBlock } from '../../../RecentActionsBlock';
import { IRecentActions } from '../../../RecentActionsBlock/types/recentActionCard';

import styles from './RecentActionsCard.module.scss';

export type RecentActionsCardProps = {
  recentActions: IRecentActions;
  getRecentActions: () => void;
};

export const RecentActionsCard = (props: RecentActionsCardProps) => {
  const { recentActions, getRecentActions } = props;

  const cx = useStyles(styles);
  const isMobile = useIsMobile();

  return (
    <div className={cx('wrapper', 'recentAction', { isMobile })}>
      <RecentActionsBlock recentActions={recentActions} isMobile={isMobile} onDelete={getRecentActions} />
    </div>
  );
};
