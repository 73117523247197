import React from 'react';

import { Button, Space, Typography } from '@sravni/react-design-system';

import styles from '../styles/PropositionCardFooter.module.scss';
import { PropositionCardFooterProps } from '../types/PropositionCardFooter.types';

export const PropositionCardFooter = ({ href, title, description, onButtonClick }: PropositionCardFooterProps) => {
  return (
    <Space size={[24, 4]} align="center" wrap className={styles.footer}>
      <Typography.Link href={href} onClick={() => onButtonClick()}>
        <Button variant="secondary" size={36}>
          {title}
        </Button>
      </Typography.Link>
      <Typography.Text className={styles.description}>{description}</Typography.Text>
    </Space>
  );
};
