import React, { useCallback, useEffect, useState } from 'react';

import { Typography } from '@sravni/react-design-system';

import { IDictionaryItem, IMaterialItem, IRubricItem } from '../../interfaces/news';
import { getDictionary, getMaterialsPopular, getRubrics } from '../../services/news';
import commonStyles from '../../styles/common.module.scss';
import { Preloader } from '../ui/Card/Preloader';

import { BlocksLine } from './BlocksLine';
import styles from './materials.module.scss';
import { Tabs } from './Tabs';

const News = React.memo(() => {
  const [dictionary, setDictionary] = useState<IDictionaryItem[]>([]);
  const [rubrics, setRubrics] = useState<IRubricItem[]>([]);
  const [materials, setMaterials] = useState<IMaterialItem[]>([]);
  const [fetching, setFetchingStatus] = useState(true);

  const fetchDictionary = useCallback(async () => {
    if (!dictionary?.length) {
      try {
        const { items } = await getDictionary({ type: 'rubrics' });
        setDictionary(items);
      } catch (error) {
        console.error(error);
      }
    }
  }, [dictionary]);

  const fetchRubrics = useCallback(async () => {
    if (!rubrics?.length) {
      try {
        const { items } = await getRubrics({ placement: 'homePage' });
        setRubrics(items);
      } catch (error) {
        console.error(error);
      }
    }
  }, [rubrics]);

  const fetchMaterials = useCallback(async () => {
    if (!materials?.length) {
      try {
        const items = await getMaterialsPopular({
          limit: 8,
          placement: 'homePage',
          placementLimit: 3,
        });
        setMaterials(items);
      } catch (error) {
        console.error(error);
      }
    }

    setFetchingStatus(false);
  }, [materials]);

  useEffect(() => {
    setFetchingStatus(true);
    fetchDictionary();
    fetchRubrics();
    fetchMaterials();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const content =
    fetching || !materials?.length ? <Preloader /> : <BlocksLine items={materials} dictionary={dictionary} isVisible />;

  return (
    <div className={commonStyles.wrapper}>
      <div className={commonStyles.inner}>
        <a className={styles.headingLink} href={'/novosti/'}>
          <Typography.Heading level={3}>Журнал Сравни</Typography.Heading>
        </a>
        <Tabs rubrics={rubrics} />
        {content}
        <Typography.Text size={14}>
          <Typography.Link href={'/novosti/'} className={commonStyles['header-link']} strong>
            Весь журнал
          </Typography.Link>
        </Typography.Text>
      </div>
    </div>
  );
});

export default News;
