import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const hotServicesEvent = (eventCategory: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory,
      eventAction: 'Переход из плиток|Сервисы',
    }),
  );
};
