import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const mortgageEvent = (eventLabel = 'Выгодные вклады|Открыть вклад') => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Ипотека',
      eventAction: 'Переход из плиток с деталями',
      eventLabel,
    }),
  );
};

export const mortgageInsuranceEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Страхование ипотеки',
      eventAction: 'Переход из плиток с деталями',
      eventLabel: 'Ставки по ипотеке|Страхование ипотеки',
    }),
  );
};
