import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './MortgageCard.module.scss';

const item: IProductData = {
  key: TYPES.MORTGAGE,
  title: 'Ипотека',
  eventCategory: 'Ипотека',
  eventAction: 'Переход из плиток',
  subtitle: '',
  pageUrl: '/ipoteka/',
  picture: [
    <source
      key={`source-product-${TYPES.MORTGAGE_INSURANCE}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.MORTGAGE_INSURANCE}`} src={require('./img_mob.png')} alt="ipoteka" />,
  ],
};

export const MortgageCard = () => <LinkCard item={item} className={styles.mortgage} />;
