import { Tabs } from '@sravni/react-design-system';
import { Deposit } from '@sravni/react-icons';

import { BarChart } from '../../BarChart';
import { PropositionCard } from '../../PropositionCard';
import { PropositionCardFooter } from '../../PropositionCard/ui/PropositionCardFooter';
import { useDepositRates } from '../hooks/useDepositRates';
import styles from '../styles/DepositRates.module.scss';
import { depositRatesEvent } from '../utils/depositRatesEvents';
import { depositRatesTermMap, depositTermKeysMap, prepareDataByDepositRates } from '../utils/depositsRates';

export const DepositRates = () => {
  const rates = useDepositRates();

  const handleChangePeriod = (index: number) => {
    const depositTerm = depositTermKeysMap[index];
    depositRatesEvent(`Тип - на ${depositRatesTermMap[depositTerm]}`);
  };

  if (!rates.length) return null;

  return (
    <PropositionCard title="Выгодные вклады" subTitle="Проверили ставки в 24 банках" icon={<Deposit />}>
      <Tabs view="badge" defaultIndex={3} className={styles.tabs} onChange={handleChangePeriod}>
        {depositTermKeysMap.map((term) => (
          <Tabs.TabPane key={term} title={depositRatesTermMap[term].toLocaleUpperCase()}>
            <BarChart barDirection="horizontal" data={prepareDataByDepositRates(rates)[term]} />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <PropositionCardFooter
        href="/vklady/"
        title="Открыть вклад"
        description={'Найдем самую\nвыгодную ставку'}
        onButtonClick={depositRatesEvent}
      />
    </PropositionCard>
  );
};
