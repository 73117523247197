import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const depositRatesEvent = (eventLabel = 'Выгодные вклады|Открыть вклад') => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Вклады',
      eventAction: 'Переход из плиток с деталями',
      eventLabel,
    }),
  );
};
