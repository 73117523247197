import React from 'react';

import { Layout, Space } from '@sravni/react-design-system';
import { useIsMobile, useStyles } from '@sravni/react-utils';

import { LayoutWrapper } from '../../../LayoutWrapper';
import { Currencies } from '../../Currencies/ui/Currencies';
import { HotServices } from '../../HotServices';
import { Reviews } from '../../Reviews';
import styles from '../styles/Container.module.scss';

export const Container = () => {
  const cx = useStyles(styles);
  const isMobile = useIsMobile();

  return (
    <LayoutWrapper topBorders={true} bottomBorders={false}>
      <Layout className={cx('layout')}>
        <Space className={cx('cards')} size={24} wrap={isMobile}>
          <Currencies className={styles.currencies} />
          <Reviews className={styles.reviewBlock} />
          <HotServices className={styles.hotServicesBlock} />
        </Space>
      </Layout>
    </LayoutWrapper>
  );
};
