import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './DepositsCard.module.scss';

const item: IProductData = {
  key: TYPES.DEPOSITS,
  title: 'Вклады',
  eventCategory: 'Вклады',
  eventAction: 'Переход из плиток',
  subtitle: '',
  pageUrl: '/vklady/',
  picture: [
    <source
      key={`source-product-${TYPES.MORTGAGE_INSURANCE}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.MORTGAGE_INSURANCE}`} src={require('./img_mob.png')} alt="vklady" />,
  ],
};

export const DepositsCard = () => <LinkCard item={item} className={styles.deposits} />;
