import { formatDate } from 'date-fns/format';
import { ru } from 'date-fns/locale';
import React from 'react';

import { Badge, Layout, Space, Typography } from '@sravni/react-design-system';
import { useIsMobile, useStyles } from '@sravni/react-utils';

import { TODAY } from '../../../../constants/today';
import { LayoutWrapper } from '../../../LayoutWrapper';
import { CreditRates } from '../../CreditRates';
import { DepositRates } from '../../DepositRates';
import { MortgageRates } from '../../Mortgage/ui/MortgageRates';
import styles from '../styles/Container.module.scss';

export const Container = () => {
  const cx = useStyles(styles);
  const isMobile = useIsMobile();

  return (
    <LayoutWrapper topBorders={false} bottomBorders={false}>
      <Layout className={cx('layout')}>
        <Space direction="vertical" size={isMobile ? 16 : 24}>
          <Space size={16} align="center" className={cx('h-pl-16 h-pr-16')}>
            <Typography.Heading level={3}>Мониторинг рынка</Typography.Heading>
            <Badge text={formatDate(TODAY, 'LLLL yyyy', { locale: ru })} variant="primary" className={cx('badge')} />
          </Space>
          <Space className={cx('cards')} size={isMobile ? 12 : 24}>
            <CreditRates />
            <DepositRates />
            <MortgageRates />
          </Space>
        </Space>
      </Layout>
    </LayoutWrapper>
  );
};
