import { CreditRatesDTO, CreditRateTermCodes } from '../../../../interfaces/creditRates';
import { BarChartData } from '../../BarChart/types/BarChart.types';

export const creditRatesTermMap: Record<CreditRateTermCodes, string> = {
  till_half_year: '6 мес',
  about_year: '1 год',
  about_3_years: '3 года',
  about_5_years: '5 лет',
};

export const creditTermKeysMap = Object.keys(creditRatesTermMap) as CreditRateTermCodes[];

export const prepareDataByCreditRates = (data: CreditRatesDTO): BarChartData[] => {
  const lastMonthCreditRates = data[data.length - 1];
  const preparedData = [...lastMonthCreditRates.banks]
    .sort((bank1, bank2) => bank1.rate - bank2.rate)
    .slice(0, 6)
    .map((bank) => ({ value: bank.rate, logo: bank.iconSrc }));

  return preparedData;
};
