import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreditRatesDTO, CreditRateTermCodes } from '../../../../interfaces/creditRates';
import { fetchCreditRates } from '../../../../store/reducers/creditRates';
import { creditRatesSelector } from '../../../../store/selectors/creditRates';

export const useCreditRates = (term: CreditRateTermCodes): CreditRatesDTO => {
  const dispatch = useDispatch<any>();
  const rates = useSelector(creditRatesSelector);

  React.useEffect(() => {
    dispatch(fetchCreditRates(term));
  }, [term, dispatch]);

  return rates;
};
