import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './CreditCard.module.scss';

const item: IProductData = {
  key: TYPES.CREDIT,
  customStyle: {},
  title: 'Кредиты',
  eventCategory: 'Подбор кредита',
  eventAction: 'Переход из плиток',
  subtitle: 'Узнайте, кто одобрит',
  pageUrl: '/kredity/onlajn-zayavka-na-kredit/',
  picture: [
    <source
      key={`source-product-${TYPES.CREDIT}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.CREDIT}`} src={require('./img_mob.png')} alt="credit" />,
  ],
};

export const CreditCard = () => <LinkCard item={item} className={styles.credit} />;
