import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useIsPhone } from '@sravni/react-utils';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './BannerCard.module.scss';

export const BannerCard = () => {
  const isMobile = useIsPhone();

  const item: IProductData = React.useMemo(() => (
    {
      key: TYPES.BANNER,
      customStyle: {},
      title: <Typography.Heading level={isMobile ? 4 : 5} className={styles.text}>{'Выбирайте \nвыгоду, \nа не банки!'}</Typography.Heading>,
      eventCategory: 'BANNER',
      eventAction: 'Переход из плиток',
      pageUrl: '/vklady/promo_first/',
      picture: [
        <source
          key={`source-product-${TYPES.BANNER}-webp`}
          srcSet={require('./img.png')}
          media={`(min-width: ${styles.breakpointTablet})`}
          type="image/webp"
        />,
        <img key={`img-product-${TYPES.BANNER}`} srcSet={require('./img_mob.png')} alt="banner" />,
      ],
    }
  ), [isMobile])

  return <LinkCard item={item} className={styles.banner} cardClassName={styles.card} />
};
