import { Grid, Icon, Space, Typography } from '@sravni/react-design-system';
import { Deposit, Exchange, InterfaceHome, MoneyCoins, Scoring, TravelVacation } from '@sravni/react-icons';
import { useStyles } from '@sravni/react-utils';

import styles from '../styles/HotServices.module.scss';
import { hotServicesEvent } from '../utils/hotServicesEvents';

const HOT_SERVICES = [
  {
    title: 'Кредитный\nкалькулятор',
    icon: <MoneyCoins />,
    href: '/kredity/kalkuljator/',
    eventCategory: 'Кредитный калькулятор',
  },
  {
    title: 'Калькулятор\nвклада',
    icon: <Deposit />,
    href: '/vklady/kalkuljator/',
    eventCategory: 'Калькулятор вклада',
  },
  {
    title: 'Ипотечный\nкалькулятор',
    icon: <InterfaceHome />,
    href: '/ipoteka/kalkuljator-ipoteki/',
    eventCategory: 'Ипотечный калькулятор',
  },
  { title: 'Конвертация\nвалют', icon: <Exchange />, href: '/valjuty/', eventCategory: 'Валюты' },
  { title: 'Авиабилеты\nи путешествия', icon: <TravelVacation />, href: '/booking-avia/', eventCategory: 'Букинг' },
  { title: 'Проверка\nштрафов ГИБДД', icon: <Scoring />, href: '/shtrafy/', eventCategory: 'Штрафы' },
];

type HotServicesProps = {
  className?: string;
};

export const HotServices = ({ className }: HotServicesProps) => {
  const cx = useStyles(styles);

  return (
    <Space direction="vertical" className={cx(className, 'gridContainer')}>
      <Grid.Row gutter={[16, 16]} wrap className={cx('row')}>
        {HOT_SERVICES.map(({ title, icon, href, eventCategory }) => (
          <Grid.Col key={title} className={styles.col}>
            <Typography.Link
              href={href}
              color="dark"
              className={cx('link')}
              onClick={() => hotServicesEvent(eventCategory)}
            >
              <Icon color="blue" size={24} icon={icon} />
              <Typography.Heading level={6} className={cx('title')}>
                {title}
              </Typography.Heading>
            </Typography.Link>
          </Grid.Col>
        ))}
      </Grid.Row>
    </Space>
  );
};
