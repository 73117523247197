import { Card, Space, Typography } from '@sravni/react-design-system';

import image from '../images/insuranceCard.png';
import styles from '../styles/insuranceCard.module.scss';
import { mortgageInsuranceEvent } from '../utils/mortgageEvents';

export const InsuranceCard = () => {
  return (
    <Typography.Link href="/strahovanie-ipoteki/" onClick={() => mortgageInsuranceEvent()}>
      <Card color="green" size={16} className={styles.card}>
        <Space direction="column" justify="space-between">
          <Space size={4} direction="column">
            <Typography.Text size={16} strong className="h-color-D100">
              Страхование ипотеки
            </Typography.Text>
            <Typography.Text size={12} className="h-color-D60">
              Сэкономьте на страховке
              <br />
              до 40% стоимости
            </Typography.Text>
          </Space>
        </Space>
        <img src={image} width={72} height={62} className={styles.image} alt="" />
      </Card>
    </Typography.Link>
  );
};
