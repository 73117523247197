import React, { useMemo } from 'react';

import { Avatar, Grid, Icon, Skeleton, Space, Typography } from '@sravni/react-design-system';
import { ArrowRight } from '@sravni/react-icons';
import { useStyles } from '@sravni/react-utils';

import { IAggregatedRate } from '../../../../interfaces/currencies';
import styles from '../styles/Currencies.module.scss';
import { currencyEvent } from '../utils/currencyEvents';

enum CurrencyDirection {
  UP = 'up',
  DOWN = 'down',
}

type TCurrencyItem = {
  link?: string;
  code: string;
  name?: string;
};

interface ICurrencyItemProps {
  item: TCurrencyItem;
  bankCurrency: IAggregatedRate;
}

const { Row, Col } = Grid;

const getCurrencyString = (number: number): string =>
  number >= 1000
    ? String(Math.round(number)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    : number.toFixed(2).replace('.', ',');

export const CurrencyItem: React.FC<ICurrencyItemProps> = React.memo(({ item, bankCurrency }) => {
  const cx = useStyles(styles);

  const curDir: CurrencyDirection = useMemo(() => {
    return bankCurrency?.officialRate.currentPrice > bankCurrency?.officialRate.previousPrice
      ? CurrencyDirection.UP
      : CurrencyDirection.DOWN;
  }, [bankCurrency]);

  const diff: number = useMemo(
    () => Math.abs(bankCurrency?.officialRate.currentPrice - bankCurrency?.officialRate.previousPrice),
    [bankCurrency],
  );

  if (!bankCurrency) return <Skeleton.Paragraph className={styles.skeleton} />;

  return (
    <Typography.Link href={item.link} className={styles.link} onClick={() => currencyEvent(item.code)}>
      <Row gutter={[0, 8]} align="middle" wrap>
        <Col span={{ mobile: 12, tablet: 4 }}>
          <Space align="center" size={12}>
            <Avatar size={36} src={bankCurrency.currency.iconX36Url} className={styles.flagIcon} />
            <Typography.Text size={14} strong>
              {item.code}
            </Typography.Text>
          </Space>
        </Col>
        <Col span={{ mobile: 12, tablet: 4 }}>
          <Space justify="center">
            <Space size={8} direction="horizontal" align="center">
              <Typography.Text size={14}>{getCurrencyString(bankCurrency.officialRate.currentPrice)}</Typography.Text>
              <Typography.Text size={12} className={cx('currencyItem', `${curDir}`)}>
                {curDir === CurrencyDirection.UP ? '+' : '-'}
                {getCurrencyString(diff)}
              </Typography.Text>
            </Space>
          </Space>
        </Col>
        <Col span={4} className={styles.colThird}>
          <Space justify="flex-end">
            <Icon icon={<ArrowRight />} className="h-color-D20" />
          </Space>
        </Col>
      </Row>
    </Typography.Link>
  );
});
