import { DocumentEdit, MoneyCoins } from '@sravni/react-icons';

import { ACTION_TYPE, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { IMfoDeal, IMfoDraft } from '../types/actionDataTypes';
import { MFO_STEP } from '../types/mfoStep';
import { IRecentActionCard } from '../types/recentActionCard';

const link = '/mfo/credit/?placement=web_banner_mainPage_lastAction&backwards_url=https://master.sravni.ru.dev.lan/';
const getDescription = (summ?: number) =>
  summ ? `Займ на\u00A0${Intl.NumberFormat('ru-RU').format(summ)}\u00A0₽` : 'Займ';

export const getMfoDraft = (action: IMfoDraft): IRecentActionCard => {
  const { payload } = action;

  return {
    actionId: action.actionId,
    cardColor: 'blue',
    hintColor: undefined,
    buttonVariant: 'outlined',
    hint: 'Продолжить заполнение',
    icon: <DocumentEdit />,
    description: getDescription(payload.summ),
    buttonText: 'Продолжить',
    product: `${PRODUCT_TYPE.MFO}-${ACTION_TYPE.DRAFT}`,
    link,
  };
};

export const getMfoDeal = (action: IMfoDeal): IRecentActionCard => {
  const { payload } = action;

  const mfoDeal: IRecentActionCard = {
    actionId: action.actionId,
    hintColor: undefined,
    description: getDescription(payload.summ),
    buttonVariant: 'outlined',
    hint: 'Продолжить заполнение',
    buttonText: 'Продолжить',
    product: `${PRODUCT_TYPE.MFO}-${ACTION_TYPE.DEAL}`,
    cardColor: 'blue',
    icon: <MoneyCoins />,
    link,
  };

  switch (payload.step) {
    case MFO_STEP.OFFER:
    case MFO_STEP.PAYMENT_DETAILS_WAITING:
    case MFO_STEP.PAYMENT_DETAILS:
    case MFO_STEP.PAYMENT_DETAILS_BANK_SELECTION:
    case MFO_STEP.PAYMENT_DETAILS_ERROR: {
      mfoDeal.hint = 'Привяжите карту';
      mfoDeal.buttonText = 'Перейти';
      mfoDeal.hintColor = 'blue';
      mfoDeal.buttonVariant = 'primary';
      mfoDeal.product = `${PRODUCT_TYPE.MFO}-${MFO_STEP.OFFER}`;

      break;
    }

    case MFO_STEP.DOCUMENTS:
    case MFO_STEP.DOCUMENTS_DECLINED: {
      mfoDeal.hint = 'Загрузите документы';
      mfoDeal.buttonText = 'Перейти';
      mfoDeal.hintColor = 'blue';
      mfoDeal.buttonVariant = 'primary';
      mfoDeal.product = `${PRODUCT_TYPE.MFO}-${ACTION_TYPE.DOCUMENTS}`;

      break;
    }

    case MFO_STEP.CONTRACT_TERMS_WAITING:
    case MFO_STEP.CONTRACT_TERMS:
    case MFO_STEP.SIGNATURE: {
      mfoDeal.hint = 'Подпишите договор';
      mfoDeal.buttonText = 'Перейти';
      mfoDeal.hintColor = 'blue';
      mfoDeal.buttonVariant = 'primary';
      mfoDeal.product = `${PRODUCT_TYPE.MFO}-${ACTION_TYPE.SIGNATURE}`;

      break;
    }
  }

  return mfoDeal;
};
