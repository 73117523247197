import { Card, Icon, Space, Typography } from '@sravni/react-design-system';
import { Bank, CoinStack, Umbrella } from '@sravni/react-icons';
import { useIsMobile, useStyles } from '@sravni/react-utils';

import image from '../images/horn.png';
import styles from '../styles/Reviews.module.scss';
import { reviewEvent } from '../utils/reviewEvents';

const REVIEW_CARDS = [
  { title: 'Отзывы\nо банках', icon: <Bank />, href: '/vklady/otzyvy/' },
  { title: 'Отзывы\nоб МФО', icon: <CoinStack />, href: '/zaimy/otzyvy/' },
  { title: 'Отзывы\nо страховых', icon: <Umbrella />, href: '/strakhovye-kompanii/otzyvy/' },
];

type ReviewsProps = {
  className?: string;
};

export const Reviews = ({ className }: ReviewsProps) => {
  const cx = useStyles(styles);
  const isMobile = useIsMobile();

  return (
    <Space direction="vertical" size={isMobile ? 16 : 24} className={className}>
      <Typography.Heading level={3}>Сервисы</Typography.Heading>
      <Space size={8} direction="column">
        <Typography.Link href={'/bank/otzyvy/add/'} color="dark" className={cx('link')} onClick={() => reviewEvent()}>
          <Card size={16} color="blue" className={cx('card')}>
            <Space size={4} direction="column">
              <Typography.Heading level={5}>Оставьте отзыв</Typography.Heading>
              <Typography.Text size={12}>Поможем решить проблему</Typography.Text>
            </Space>
            <img src={image} height={98} width={155} className={cx('image')} alt="" />
          </Card>
        </Typography.Link>
        <Space size={8} direction="row">
          {REVIEW_CARDS.map(({ title, icon, href }) => (
            <Typography.Link
              key={title}
              href={href}
              color="dark"
              className={cx('link')}
              onClick={() => reviewEvent(title)}
            >
              <Card size={16} className={cx('reviewCard')}>
                <Typography.Heading level={6} className={cx('reviewTitle')}>
                  {title}
                </Typography.Heading>
                <Icon size={24} icon={icon} className={cx('reviewIcon')} />
              </Card>
            </Typography.Link>
          ))}
        </Space>
      </Space>
    </Space>
  );
};
