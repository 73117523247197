import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const currencyEvent = (code = 'Все курсы') => {
  pushEvent(
    makeMainGEvent({
      eventCategory: 'Валюты',
      eventAction: 'Переход из плиток с деталями',
      eventLabel: `Курс валют|${code}`,
    }),
  );
};
