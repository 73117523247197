import React, { useEffect } from 'react';

import { Typography, Layout } from '@sravni/react-design-system';
import { useBoolean, useIsPhone, useStyles } from '@sravni/react-utils';

import { LayoutWrapper } from '../../../LayoutWrapper';
import { useRecentActions } from '../../../RecentActionsBlock/hooks/useRecentActions';
import { BannerCard } from '../BannerCard';
import { CreditCard } from '../CreditCard';
import { CreditsRatingCard } from '../CreditsRatingCard';
import { DepositsCard } from '../DepositsCard';
import { KaskoCard } from '../KaskoCard';
import { LoansCard } from '../LoansCard';
import { MortgageCard } from '../MortgageCard';
import { MortgageInsuranceCard } from '../MortgageInsuranceCard';
import { OsagoCard } from '../OsagoCard';
import { RecentActionsCard } from '../RecentActionsCard';
import { ServiceMenuCard } from '../ServiceMenuCard';
import { SportInsuranceCard } from '../SportInsuranceCard';
import { StrahovanieNedvizhimostiCard } from '../StrahovanieNedvizhimostiCard';
import { VzrCard } from '../VzrCard';

import styles from './ProductCardsContainer.module.scss';

//Статичный блок, открывается по запросу
const withBanner = true;

export const ProductCardsContainer = React.memo(() => {
  const { recentActions, getRecentActions } = useRecentActions();
  const { orders, calculations } = recentActions;
  const [withRecentActions, setWithRecentActions] = useBoolean(false);
  const isMobile = useIsPhone();
  const cx = useStyles(styles);

  const cardsContainerClassnames = cx('gridContainer', {
    recentActions: withRecentActions,
    withBanner: withBanner,
  });

  useEffect(() => {
    if (!!orders?.length || !!calculations?.length) {
      setWithRecentActions.on();
    } else {
      setWithRecentActions.off();
    }
  }, [orders, calculations, setWithRecentActions]);

  return (
    <>
      <LayoutWrapper topBorders={false} bottomBorders={false} whiteBackground suppressHydrationWarning>
        <Layout className={`${cx('titleWrapper')}`}>
          <Typography.Heading level={3} as={'h1'} className={cx('title')}>
            Помогаем сохранить деньги
          </Typography.Heading>
        </Layout>
        <Layout className={cx('barContainer')}>
          <div className={cx('bar')}>
            <div className={cardsContainerClassnames}>
              {withRecentActions && (
                <RecentActionsCard recentActions={recentActions} getRecentActions={getRecentActions} />
              )}
              {withBanner && <BannerCard/>}
              <OsagoCard />
              <CreditCard />
              <LoansCard />
              <MortgageInsuranceCard />
              <CreditsRatingCard />
              <DepositsCard />
              <VzrCard />
              <SportInsuranceCard />
              {(isMobile || !withBanner || !withRecentActions) && (
                  <>
                    <KaskoCard />
                    <MortgageCard />
                  </>
                )
              }
              <StrahovanieNedvizhimostiCard />
              <ServiceMenuCard />
            </div>
          </div>
        </Layout>
      </LayoutWrapper>
    </>
  );
});
