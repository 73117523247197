import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './VzrCard.module.scss';

const item: IProductData = {
  key: TYPES.VZR,
  title: (
    <>
      Страховка <br /> туриста
    </>
  ),
  eventCategory: 'ВЗР',
  eventAction: 'Переход из плиток',
  subtitle: '',
  pageUrl: '/vzr/',
  picture: [
    <source
      key={`source-product-${TYPES.MORTGAGE_INSURANCE}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.MORTGAGE_INSURANCE}`} src={require('./img_mob.png')} alt="vzr" />,
  ],
};

export const VzrCard = () => <LinkCard item={item} className={styles.vzr} />;
