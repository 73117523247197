import React from 'react';

import { Badge } from '@sravni/react-design-system';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './MortgageInsuranceCard.module.scss';

const item: IProductData = {
  key: TYPES.MORTGAGE_INSURANCE,
  customStyle: {},
  title: (
    <>
      Страховка
      <br />
      ипотеки
    </>
  ),
  eventCategory: 'Страхование ипотеки',
  eventAction: 'Переход из плиток',
  subtitle: '',
  badge: <Badge color="violet" variant="primary" text="приз 500 000 ₽" />,
  pageUrl: '/strahovanie-ipoteki/',
  picture: [
    <source
      key={`source-product-${TYPES.MORTGAGE_INSURANCE}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.MORTGAGE_INSURANCE}`} src={require('./img_mob.png')} alt="strahovanie ipoteki" />,
  ],
};

export const MortgageInsuranceCard = () => <LinkCard item={item} className={styles.mortgageInsurance} />;
