// import { isEqual, startOfToday, startOfDay } from 'date-fns';
import React from 'react';

// import { Badge } from '@sravni/react-design-system';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './OsagoCard.module.scss';

// const dateEnd = new Date(2025, 2, 31);

const item: IProductData = {
  key: TYPES.OSAGO,
  customStyle: {},
  title: <>{'ОСАГО'}</>,
  eventCategory: 'ОСАГО',
  eventAction: 'Переход из плиток',
  subtitle: 'Поможем сэкономить до\u00A05\u00A0500\u00A0₽',
  pageUrl: '/osago/',
  // badge: !isEqual(startOfToday(), startOfDay(dateEnd)) ? (
  //   <Badge color="violet" variant="primary" text="Розыгрыш" />
  // ) : null,
  picture: [
    <source
      key={`source-product-${TYPES.OSAGO}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.OSAGO}`} srcSet={require('./img_mob.png')} alt="osago" />,
  ],
};

export const OsagoCard = () => <LinkCard item={item} className={styles.osago} />;
