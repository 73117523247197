import React from 'react';

import { Tabs } from '@sravni/react-design-system';
import { MoneyCoins } from '@sravni/react-icons';

import { INITIAL_TERM_CODE } from '../../../../constants/creditRates';
import { CreditRateTermCodes } from '../../../../interfaces/creditRates';
import { BarChart } from '../../BarChart';
import { PropositionCard } from '../../PropositionCard';
import { PropositionCardFooter } from '../../PropositionCard/ui/PropositionCardFooter';
import { useCreditRates } from '../hooks/useCreditRates';
import styles from '../styles/CreditRates.module.scss';
import { creditRatesTermMap, creditTermKeysMap, prepareDataByCreditRates } from '../utils/creditRates';
import { creditRatesEvent } from '../utils/creditRatesEvents';

export const CreditRates = () => {
  const [activeTerm, setActiveTerm] = React.useState<CreditRateTermCodes>(INITIAL_TERM_CODE);
  const rates = useCreditRates(activeTerm);

  const handleChangePeriod = (index: number) => {
    const loanTerm = creditTermKeysMap[index];
    setActiveTerm(loanTerm);
    creditRatesEvent(`Тип - на ${creditRatesTermMap[loanTerm]}`);
  };

  if (!rates.length) return null;

  return (
    <PropositionCard title="Ставки по кредитам" subTitle="на основе реальных выдач" icon={<MoneyCoins />}>
      <Tabs view="badge" defaultIndex={3} onChange={handleChangePeriod} className={styles.tabs}>
        {creditTermKeysMap.map((term) => (
          <Tabs.TabPane key={term} title={creditRatesTermMap[term].toUpperCase()} className="h-color-G100">
            <BarChart barDirection="vertical" data={prepareDataByCreditRates(rates)} />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <PropositionCardFooter
        href="/kredity/onlajn-zayavka-na-kredit/?amount=500000&period=P5Y&purpose=Money/"
        title="Узнать свою ставку"
        description={'Подберем банки,\nготовые выдать кредит'}
        onButtonClick={creditRatesEvent}
      />
    </PropositionCard>
  );
};
