import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

export const reviewEvent = (title = 'Отзывы') => {
  pushEvent(
    makeMainGEvent({
      eventCategory: title.replace(/\n|\r/g, ' '),
      eventAction: 'Переход из плиток|Сервисы',
    }),
  );
};
