import { DepositRatesModel, DepositRatesTermCodes } from '../../../../interfaces/depositRates';
import { BarChartData } from '../../BarChart/types/BarChart.types';

export const depositRatesTermMap: Record<DepositRatesTermCodes, string> = {
  oneMonth: '1 мес',
  threeMonth: '3 мес',
  sixMonth: '6 мес',
  oneYear: '1 год',
};

export const depositTermKeysMap = Object.keys(depositRatesTermMap) as DepositRatesTermCodes[];

export const prepareDataByDepositRates = (data: DepositRatesModel[]) => {
  if (!Array.isArray(data)) return undefined;

  return data.reduce<Record<DepositRatesTermCodes, BarChartData[]>>(
    (acc, deposit) => {
      acc[deposit.period].push({
        logo: deposit.bankDetail.logotypes.square,
        value: Math.round(deposit.actualRate.value * 100) / 100,
      });
      return acc;
    },
    { oneMonth: [], threeMonth: [], sixMonth: [], oneYear: [] },
  );
};
