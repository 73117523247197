import { House } from '@sravni/react-icons';

import { BarChart } from '../../BarChart';
import { PropositionCard } from '../../PropositionCard';
import { PropositionCardFooter } from '../../PropositionCard/ui/PropositionCardFooter';
import { useMortgages } from '../hooks/useMortgages';
import { prepareDataByMortgageRates } from '../utils/mortgage';
import { mortgageEvent } from '../utils/mortgageEvents';

import { InsuranceCard } from './InsuranceCard';

export const MortgageRates = () => {
  const rates = useMortgages();

  const handleMortgageBarClick = (title: string, value: number) => {
    mortgageEvent(`Тип - ${title}|Ставка - ${value}%`);
  };

  return (
    <PropositionCard title="Ставки по ипотеке" subTitle="Проверили ставки в 59 банках" icon={<House />}>
      <InsuranceCard />
      <BarChart
        data={prepareDataByMortgageRates(rates)}
        className="h-mt-16 h-mb-24"
        barDirection="horizontal"
        legend
        reverse
        onBarClick={handleMortgageBarClick}
      />
      <PropositionCardFooter
        href="/ipoteka/"
        title="Узнать свою ставку"
        description={'и рассчитать\nежемесячный платеж'}
        onButtonClick={mortgageEvent}
      />
    </PropositionCard>
  );
};
