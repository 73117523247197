import React, { useEffect, useState } from 'react';

import { Card, Space, Typography, Divider } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import { CURRENCIES_DATA_B_TEST } from '../../../../constants/currencies';
import { getBankCurrencies } from '../../../../helpers/currencies';
import { getBankRates } from '../../../../services/currencies';
import styles from '../styles/Currencies.module.scss';
import { currencyEvent } from '../utils/currencyEvents';

import { CurrencyItem } from './CurrencyItem';

type CurrenciesProps = {
  className?: string;
};

export const Currencies = React.memo(({ className }: CurrenciesProps) => {
  const isMobile = useIsMobile();

  const [bankRates, setBankRates] = useState([]);

  // TODO не используется редакс, почему? Посмотреть как блок был сделан ранее (до pe-2926)
  const fetchCurrencies = async () => {
    try {
      const banks = await getBankRates();
      setBankRates(banks);
    } catch (error) {
      setBankRates([]);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const bankCurrencies = getBankCurrencies(bankRates);

  return (
    <Space direction="vertical" size={isMobile ? 16 : 24} className={className}>
      <Typography.Heading level={3}>Курсы валют</Typography.Heading>
      <Card size={16} className={styles.card}>
        <Space size={4} direction="vertical" justify="space-between">
          <Space className={styles.itemsWrapper}>
            {CURRENCIES_DATA_B_TEST.map((item, index) => {
              const bankCurrency = bankCurrencies && bankCurrencies[item.code];

              return (
                <React.Fragment key={item.code}>
                  <CurrencyItem bankCurrency={bankCurrency} item={item} key={`currency-${index}`} />
                  {CURRENCIES_DATA_B_TEST.length - 1 !== index && <Divider className={styles.divider} />}
                </React.Fragment>
              );
            })}
          </Space>
          <Space size={16} justify="start" className={styles.footerLink}>
            <Typography.Link strong href="/valjuty/" onClick={() => currencyEvent()}>
              Все курсы
            </Typography.Link>
          </Space>
        </Space>
      </Card>
    </Space>
  );
});
