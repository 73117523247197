import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './CreditsRatingCard.module.scss';

const item: IProductData = {
  key: TYPES.CREDITS_RATING,
  title: 'Кредитный \n рейтинг',
  customStyle: {},
  eventCategory: 'Кредитный рейтинг',
  eventLabel: 'Банки',
  eventAction: 'Переход из плиток',
  pageUrl: '/kredity/scoring/',
  picture: [
    <source
      key={`source-product-${TYPES.CREDITS_RATING}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.CREDITS_RATING}`} src={require('./img_mob.png')} alt="credits rating" />,
  ],
};

export const CreditsRatingCard = () => <LinkCard item={item} className={styles.creditsRating} />;
